<template>
    <section>
      <h2 class="h5">Dados pessoais</h2>
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="form-check form-check-inline">
            <input 
              class="form-check-input custom-radio" 
              type="radio" 
              id="cpf" 
              value="cpf" 
              v-bind:checked="tipoDocumento === 'cpf'" 
              @change="$emit('update:tipoDocumento', 'cpf')" 
              required
            >
            <label class="form-check-label" for="cpf">CPF</label>
          </div>
          <div class="form-check form-check-inline">
            <input 
              class="form-check-input custom-radio" 
              type="radio" 
              id="cnpj" 
              value="cnpj" 
              v-bind:checked="tipoDocumento === 'cnpj'" 
              @change="$emit('update:tipoDocumento', 'cnpj')" 
              required
            >
            <label class="form-check-label" for="cnpj">CNPJ</label>
          </div>
        </div>
      </div>
      <div class="radio-underline"></div>
      
      <!-- Campo de CPF ou CNPJ com máscara dinâmica -->
      <div class="mb-3">
        <imask-input 
          :mask="tipoDocumento === 'cpf' ? '000.000.000-00' : '00.000.000/0000-00'"
          v-bind:value="documento" 
          @input="handleNumericInput($event)" 
          @update:modelValue="$emit('update:documento', $event)"
          @blur="validateDocumento"
          id="documento" 
          class="form-control" 
          :placeholder="tipoDocumento === 'cpf' ? 'Número do CPF' : 'Número do CNPJ'" 
          :maxlength="tipoDocumento === 'cpf' ? 14 : 18"
          required
        />
      </div>
  
      <!-- Campo E-mail -->
      <div class="mb-3" v-if="tipoDocumento === 'cpf' || showAddress">
        <input 
          type="email" 
          v-bind:value="email" 
          @input="$emit('update:email', $event.target.value)" 
          id="email" 
          class="form-control" 
          placeholder="E-mail"
          required
        />
      </div>

      <!-- Campos adicionais para CNPJ -->
      <div v-if="tipoDocumento === 'cnpj'">
        <!-- Tipo de inscrição -->
        <div class="row align-items-center">
          <div class="col-md-6">
            <input 
              type="number" 
              v-bind:value="inscricaoMunicipal" 
              @input="$emit('update:inscricaoMunicipal', $event.target.value)" 
              id="inscricaoMunicipal" 
              class="form-control"
              placeholder="Inscrição Municipal"
            />
          </div>
          <div class="col-md-6">
            <input 
              type="number" 
              v-bind:value="inscricaoEstadual" 
              @input="$emit('update:inscricaoEstadual', $event.target.value)" 
              id="inscricaoEstadual" 
              class="form-control"
              placeholder="Inscrição Estadual"
            />
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
    import { handleNumericInput, validacpf, validacnpj } from '../utils/utils.js';
    export default {
        props: {
        tipoDocumento: String,
        documento: String,
        nome: String,
        email: String,
        nomeFantasia: String,
        inscricaoMunicipal: String,
        inscricaoEstadual: String,
        showAddress: Boolean
        },
        methods: {
          handleNumericInput,
          validacpf,
          validacnpj,
          validateDocumento() {
            let isValid = false;
            if (this.tipoDocumento === 'cpf') {
              isValid = this.validacpf(this.documento);
            } else if (this.tipoDocumento === 'cnpj') {
              isValid = this.validacnpj(this.documento);
            }
            if (!isValid) {
              this.$emit('documento-invalido', 'Documento inválido!');
            }
          }
        }
    }
  </script>
  
  <style scoped>
  .radio-underline {
    width: 100%;
    height: 2px;
    background-color: #D9D9D9;
    margin-top: -10px;
    margin-bottom: 20px;
  }
</style>